import React from 'react'
import { Navbar } from '../../../../components'
import { Footer } from '../../../../components'

import aiyara_1 from "../../../../assets/aiyara/aiyara_1.png"
import aiyara_2 from "../../../../assets/aiyara/aiyara_2.png"
import aiyara_3 from "../../../../assets/aiyara/aiyara_3.png"
import aiyara_4 from "../../../../assets/aiyara/aiyara_4.png"
import aiyara_5 from "../../../../assets/aiyara/aiyara_5.png"
import aiyara_6 from "../../../../assets/aiyara/aiyara_6.png"
import aiyara_47 from "../../../../assets/aiyara/aiyara_47.mp4"
import aiyara_7 from "../../../../assets/aiyara/aiyara_7.png"
import aiyara_8 from "../../../../assets/aiyara/aiyara_8.png"
import aiyara_9 from "../../../../assets/aiyara/aiyara_9.png"
import aiyara_10 from "../../../../assets/aiyara/aiyara_10.png"
import aiyara_11 from "../../../../assets/aiyara/aiyara_11.png"
import aiyara_46 from "../../../../assets/aiyara/aiyara_46.mp4"
import aiyara_12 from "../../../../assets/aiyara/aiyara_12.png"
import aiyara_13 from "../../../../assets/aiyara/aiyara_13.png"
import aiyara_14 from "../../../../assets/aiyara/aiyara_14.png"
import aiyara_15 from "../../../../assets/aiyara/aiyara_15.png"
import aiyara_16 from "../../../../assets/aiyara/aiyara_16.png"
import aiyara_17 from "../../../../assets/aiyara/aiyara_17.png"
import aiyara_18 from "../../../../assets/aiyara/aiyara_18.png"
import aiyara_19 from "../../../../assets/aiyara/aiyara_19.png"
import aiyara_20 from "../../../../assets/aiyara/aiyara_20.png"
import aiyara_21 from "../../../../assets/aiyara/aiyara_21.png"
import aiyara_22 from "../../../../assets/aiyara/aiyara_22.png"
import aiyara_23 from "../../../../assets/aiyara/aiyara_23.png"
import aiyara_24 from "../../../../assets/aiyara/aiyara_24.png"
import aiyara_25 from "../../../../assets/aiyara/aiyara_25.png"
import aiyara_26 from "../../../../assets/aiyara/aiyara_26.png"
import aiyara_27 from "../../../../assets/aiyara/aiyara_27.png"
import aiyara_28 from "../../../../assets/aiyara/aiyara_28.png"
import aiyara_29 from "../../../../assets/aiyara/aiyara_29.png"
import aiyara_30 from "../../../../assets/aiyara/aiyara_30.png"
import aiyara_31 from "../../../../assets/aiyara/aiyara_31.png"
import aiyara_32 from "../../../../assets/aiyara/aiyara_32.png"
import aiyara_33 from "../../../../assets/aiyara/aiyara_33.png"
import aiyara_34 from "../../../../assets/aiyara/aiyara_34.png"
import aiyara_35 from "../../../../assets/aiyara/aiyara_35.png"
import aiyara_36 from "../../../../assets/aiyara/aiyara_36.png"
import aiyara_37 from "../../../../assets/aiyara/aiyara_37.png"
import aiyara_38 from "../../../../assets/aiyara/aiyara_38.mp4"
import aiyara_39 from "../../../../assets/aiyara/aiyara_39.mp4"
import aiyara_40 from "../../../../assets/aiyara/aiyara_40.mp4"
import aiyara_41 from "../../../../assets/aiyara/aiyara_41.mp4"
import aiyara_42 from "../../../../assets/aiyara/aiyara_42.mp4"
import aiyara_43 from "../../../../assets/aiyara/aiyara_43.mp4"
import aiyara_44 from "../../../../assets/aiyara/aiyara_44.mp4"
import aiyara_45 from "../../../../assets/aiyara/aiyara_45.mp4"



import "./aiyara.css"



const Aiyara = () => {
  return (
    <div>
        <Navbar/>
        <div className='aiyara-main'>
            <h1 className='aiyara-title'>THAI AIYARA COLLAB</h1>
            <div className='aiyara-album'>

                <img src={aiyara_37}/> 
                <img src={aiyara_1}/>
                <img src={aiyara_2}/> 
                <img src={aiyara_3}/>
                <video controls src={aiyara_38} type="video/mp4"/>
                <img src={aiyara_4}/> 
                <img src={aiyara_5}/> 
                <img src={aiyara_28}/> 
                <img src={aiyara_26}/> 
                <img src={aiyara_33}/> 
                <img src={aiyara_35}/> 
                <img src={aiyara_7}/> 
                <video controls src={aiyara_39} type="video/mp4"/>
                <img src={aiyara_9}/> 
                <img src={aiyara_10}/> 
                <img src={aiyara_31}/>
                <img src={aiyara_11}/>
                <video controls src={aiyara_40} type="video/mp4"/>

                <img src={aiyara_13}/> 
                <img src={aiyara_14}/> 
                <img src={aiyara_15}/> 
                <video controls src={aiyara_41} type="video/mp4"/>
                <img src={aiyara_16}/> 
                <img src={aiyara_17}/> 
                <video controls src={aiyara_46} type="video/mp4"/>
                <img src={aiyara_18}/> 
                <img src={aiyara_19}/> 
                <img src={aiyara_20}/> 
                <video controls src={aiyara_42} type="video/mp4"/>
                <img src={aiyara_21}/>
                <img src={aiyara_22}/> 
                <video controls src={aiyara_45} type="video/mp4"/>
                <img src={aiyara_23}/> 
                <img src={aiyara_24}/> 
                <img src={aiyara_25}/> 
                <video controls src={aiyara_43} type="video/mp4"/>
                <img src={aiyara_27}/> 
                <img src={aiyara_29}/> 
                <img src={aiyara_30}/> 
                <video controls src={aiyara_44} type="video/mp4"/>
                <img src={aiyara_32}/> 
                <img src={aiyara_34}/>
                <img src={aiyara_36}/> 
                <video controls src={aiyara_47} type="video/mp4"/>


            </div>       
        </div>

        <Footer/>
    </div>  
  )
}

export default Aiyara